<template>
    <div class="mb-md-0 mb-0">
        <a :href="product.slug" :title="product.name" class="product">
            <span class="product__header lazyload background uk-position-relative" :data-bgset="imageUrl">
                <button class="product__cart uk-position-absolute" @click.prevent.stop="cartClickHandler()">
                    <i class="fa-solid fa-cart-shopping" width="30" v-if="!isLoading"></i>
                    <div class="loader" v-else><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                </button>
            </span>
            <span>
                <div class="product__info">
                    <p class="product__title">{{ product.name }}</p>
                </div>
                <div class="product__footer">
                    <div>
                        <div class="product__price mb-2 d-flex flex-column">
                            <!-- <p class="product__price--label">vanaf</p> -->
                            <p class="product__price--current product__price--current-card">€ {{ product.formatted.use_price }}</p>
                            <p v-if="product.discount_active" class="product__price--old ">€ {{ product.formatted.price }}</p>
                        </div>
                        <!-- <product-delivery-time
                            v-if="product.delivery_time"
                            :delivery-time="product.delivery_time"
                        ></product-delivery-time> -->
                    </div>

                    
                </div>
            </span>
        </a>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import WishlistButton from "./WishlistButton";
    import ProductDeliveryTime from "./ProductDeliveryTime";

    export default {
        name: "ProductCard",
        components: {WishlistButton, ProductDeliveryTime},
        data(){
            return {
                isLoading: false,
                inWishlist: this.product.in_wishlist
            }
        },
        methods: {
            cartClickHandler()
            {
                if(this.product.type === "basart") {
                    EventBus.$emit("PRODUCT_CARD_CONFIGURATOR", this.product);
                }
                if(this.product.type === "giftcard") {
                    window.location = this.routes.giftcard_page ?? 'javascript:void(0)';
                }
                else {
                    this.isLoading = true;

                    window.axios.post(this.routes.products.addToCart, {product_id: this.product.id, quantity: 1})
                        .then(res => {
                            EventBus.$emit('CART_UPDATE', res.data.cart);
                            EventBus.$emit('PRODUCT_ADDED_MODAL', {product: this.product, quantity: 1});
                            this.isLoading = false;
                        })
                        .catch(error => {
                            let message = {
                                message: this.translations.add_error,
                                pos: "top-right",
                                status: "danger",
                                timeout: 3000,
                            };

                            EventBus.$emit('NOTIFY', message);
                            this.isLoading = false;
                        });
                }
            },
        },
        computed: {
          imageUrl() {
            return this.product?.image ?? "/dist/assets/images/image_placeholder.png";
          },
        },
        props: {
            product: {
                type: Object|Array,
                required: true
            },
            routes: {
              type: Object|Array,
              required: true
            },
            translations: {
              type: Object|Array,
              required: true
            }
        }
    }
</script>

<style scoped>
    
</style>
