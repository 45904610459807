var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "uk-h2" }, [
        _vm._v(_vm._s(_vm.activeProduct.name)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-sm-row flex-column align-items-baseline" },
        [
          _c(
            "h2",
            { staticClass: "my-0 me-3 product__price--current new-price" },
            [_vm._v("€ " + _vm._s(_vm.activeProduct.use_price))]
          ),
          _vm._v(" "),
          _vm.activeProduct.use_price != _vm.activeProduct.price
            ? _c(
                "h5",
                { staticClass: "my-0 me-3 product__price--old old-price" },
                [_c("s", [_vm._v("€ " + _vm._s(_vm.activeProduct.price))])]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.activeProduct.delivery_time
        ? _c("product-delivery-time", {
            staticClass: "mt-3 mb-4",
            attrs: { "delivery-time": _vm.activeProduct.delivery_time },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.flavours && _vm.flavours.length > 1
        ? _c("section", { staticClass: "mb-4 mt-5" }, [
            _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
              _c("p", { staticClass: "my-0 me-4 flavour-picker--title" }, [
                _vm._v(_vm._s(_vm.translations.flavour)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flavour-picker flavour-picker--wide" },
              _vm._l(_vm.flavours, function (flavour) {
                return _c("product-configurator-flavour", {
                  key: flavour.id,
                  attrs: {
                    flavour: flavour,
                    flavours: _vm.flavours,
                    variation: _vm.getVariation(
                      _vm.colorId,
                      _vm.sizeId,
                      flavour.id
                    ),
                    "active-size": _vm.sizeId,
                    "active-color": _vm.colorId,
                    "active-flavour": _vm.flavourId,
                  },
                  on: { change: _vm.handleChange },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeSizes && _vm.activeSizes.length > 1
        ? _c("section", { staticClass: "mb-4 mt-5" }, [
            _c("div", { staticClass: "size-picker" }, [
              _c("div", { staticClass: "d-flex align-items-baseline mb-2" }, [
                _c("p", { staticClass: "my-0 me-4 size-picker--title" }, [
                  _vm._v(_vm._s(_vm.translations.size)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sizes" },
                [
                  _c("product-configurator-size", {
                    attrs: {
                      sizes: _vm.activeSizes,
                      colorId: _vm.colorId,
                      flavourId: _vm.flavourId,
                      sizeId: _vm.sizeId,
                      siblings: _vm.siblings,
                      "active-size": _vm.sizeId,
                      "active-color": _vm.colorId,
                      "active-flavour": _vm.flavourId,
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cart-container mt-sm-5 flex-column flex-lg-row align-items-start align-items-lg-center",
        },
        [
          _c("div", { staticClass: "d-flex cart-container-button" }, [
            _c("div", { staticClass: "cart-container-form" }, [
              _c("form", { staticClass: "form-custom" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.quantity,
                      expression: "quantity",
                    },
                  ],
                  staticClass: "uk-input uk-text-center",
                  attrs: { type: "number", name: "amount", min: "1" },
                  domProps: { value: _vm.quantity },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.quantity = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-cart uk-button-primary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addToCart.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("i", {
                        staticClass: "fa-solid fa-cart-shopping me-2 pb-1",
                        attrs: { width: "20" },
                      })
                    : _c("div", { staticClass: "loader" }, [
                        _c("i", {
                          staticClass: "fa-solid fa-circle-notch fa-spin me-2",
                        }),
                      ]),
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.translations.in_my_cart) +
                      "\n\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("wishlist-button", {
            attrs: {
              "in-wishlist": _vm.activeProduct.in_wishlist,
              "product-id": _vm.activeProduct.id,
              routes: _vm.routes,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }